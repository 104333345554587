import {
    ExtendedProfile, UserInfo,
} from './dataTypes'

export const initialExtendedProfile: ExtendedProfile = {
    assignedRoles: [],
    attributes: {
        companyId: [],
    },
    username: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: null as unknown as boolean,
}

export const initialUserInfo: UserInfo = {
    assignedRoles: [],
    avatar: '',
    companyId: null as unknown as number,
    id: null as unknown as number,
    companyName: null as unknown as string,
    name: '',
    email: '',
}

export const getAvatar = (gender: string) => {
    return gender || ''
}

export const getUserInfo = (extendedProfile: ExtendedProfile, avatar = ''): UserInfo => {
    const {
        assignedRoles = [],
        attributes,
        email,
        name,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = extendedProfile as any
    const {
        companyId: compIdArr = [],
    } = attributes
    const [companyId = null] = compIdArr

    return {
        avatar,
        assignedRoles,
        companyId,
        email,
        name,
        attributes,
    }
}
